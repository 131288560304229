import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NavBar from '../components/Navbar';
import CaseSummary from '../components/case/CaseSummary';
import CaseOverview from '../components/case/CaseOverview';
import CaseResearch from '../components/case/CaseResearch';
import CaseProblem from '../components/case/CaseProblem';
import CasePrototype from '../components/case/CasePrototype';
import CaseDesign from '../components/case/CaseDesign';
import CaseImpact from '../components/case/CaseImpact';
import CaseFeedback from '../components/case/CaseFeedback';
import CaseReflection from '../components/case/CaseReflection';
import Footer from '../components/footer';
import '../components/Global.css';
import '../components/Dark.css';
import '../components/Case.css';

const CasePage = ({ caseData: currentCase }) => {
  const showSectionLine = currentCase.showSectionLine;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="casePage">
      <Helmet>
        <title>{`${currentCase.projTitle} | Scott Tollerton - Portfolio`}</title>
        <meta name="description" content={currentCase.summary} />
        <meta property="og:title" content={`${currentCase.projTitle} | Scott Tollerton - Portfolio`} />
        <meta property="og:description" content={currentCase.summary} />
        <meta property="og:image" content={currentCase.caseSplashImg} /> {/* You can use a relevant image URL */}
        <meta property="og:type" content="website" />
        <link rel="icon" href="/assets/Misc/favicon.ico" type="image/x-icon" />
      </Helmet>
      <NavBar />
      <div className="splash-wrap" style={currentCase.caseStyle}>
        <img
          src={currentCase.caseSplashImg}
          alt={currentCase.caseSplashImgAlt}
          loading="lazy"
          className="piece-splash"
        />
      </div>
      <div className="row nm">
        <div className="stripe"></div>
        <div className="case-container">
          <CaseSummary caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseOverview caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseResearch caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseProblem caseData={currentCase} showSectionLine={showSectionLine} />
          <CasePrototype caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseDesign caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseImpact caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseFeedback caseData={currentCase} showSectionLine={showSectionLine} />
          <CaseReflection caseData={currentCase} showSectionLine={showSectionLine} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CasePage;
