import React, { useRef, useState, useEffect } from 'react';
import './Global.css';
import './Intro.css';

const Intro = () => {
  const cardRef = useRef(null);
  const [tilt, setTilt] = useState({ mouseX: 0, mouseY: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = cardRef.current.getBoundingClientRect();
    const xAxis = (width / 2 - e.clientX + left) / 90; 
    const yAxis = -(height / 2 - e.clientY + top) / 100; 
    setTilt({ mouseX: xAxis, mouseY: yAxis });
  };

  const handleMouseLeave = () => {
    setTilt({ mouseX: 0, mouseY: 0 });
  };

  const handleClickScroll = () => {
    if (cardRef.current) {
      const offsetPercentage = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--scroll-offset'));

      const viewportHeight = window.innerHeight;
      const offset = -viewportHeight * offsetPercentage;
  
      const scrollPosition = cardRef.current.offsetTop - offset;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

   useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="intro" onMouseLeave={handleMouseLeave}>
      <div className="intro-wrapper">
        <div className="md-innerbox">
          <div className="intro-text">
            <h1>Hello! I'm Scott</h1>
            <div className="introduction">
              I am a user-focused, frontend web-app developer and interaction designer. I specialize in creating engaging and intuitive user interfaces that deliver positive experiences.
            </div>
            <div className="buttons">
              <button className="button" onClick={handleClickScroll}>
                <span className="text">Selected Work</span>
              </button>
            </div>
          </div>
        </div>
        <img
          className="intro-img"
          src={process.env.PUBLIC_URL + '/assets/Misc/webart.png'}
          loading="lazy"
          alt="Designed Website Graphic"
          style={{
            transform: `rotateY(${tilt.mouseX}deg) rotateX(${tilt.mouseY}deg)`,
          }}
        />
        <div ref={cardRef} /> {/* Placeholder for scrolling to the card */}
      </div>
    </div>
  );
};

export default Intro;
