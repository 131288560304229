import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseReflection = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.reflectionSection ? '' : 'hidden'}`}>
      <div className="section">
      <div className="section-title">{caseData.ST_Reflection}</div>
        <div className="takeaways col">
          <h4>{caseData.takeawaysTitle}</h4>
            <p>{caseData.reflection1}</p>
            <p>{caseData.reflection2}</p>
            <p>{caseData.reflection3}</p>
        </div>
        </div>
    </div>
  );
};

export default CaseReflection;
