import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseFeedback = ({ caseData, showSectionLine }) => {
  return (
    <div className= {`${caseData.feedbackSection ? '' : 'hidden'}`}>
    <div className="section">
      <div className="section-title">{caseData.ST_UserFeedback}</div>
      <div className= {`feedback row ${caseData.feedbackrow1TF ? '' : 'hidden'}`}>
          <div className="feedback col">
            <h4>{caseData.UT_Feedback_Title}</h4>
            <p>{caseData.testFeedback1}</p>
            <p>{caseData.testFeedback2}</p>
            <p>{caseData.testFeedback3}</p>
            <p>{caseData.testFeedback4}</p>
            <p>{caseData.testFeedback5}</p>
          </div>
          <img src={caseData.feedbackImg} alt={caseData.feedbackImgAlt} loading="lazy" className= {`feedback-img ${caseData.feedbackImg1TF ? '' : 'hidden'}`} />
        </div>
        <div className= {`feedback row ${caseData.feedbackrow2TF ? '' : 'hidden'}`}>
          <div className="feedback col">
            <h4>{caseData.UT_Advantages_Title}</h4>
            <p>{caseData.feedbackAdv1}</p>
            <p>{caseData.feedbackAdv2}</p>
            <p>{caseData.feedbackAdv3}</p>
            <p>{caseData.feedbackAdv4}</p>
            <p>{caseData.feedbackAdv5}</p>
          </div>
          <img src={caseData.feedbackAdvImg} alt={caseData.feedbackAdvImgAlt} loading="lazy" className= {`feedback-img ${caseData.feedbackImg2TF ? '' : 'hidden'}`} />
        </div>
        <div className= {`feedback row ${caseData.feedbackrow3TF ? '' : 'hidden'}`}>
          <div className="feedback col">
            <h4>{caseData.UT_Suggestions_Title}</h4>
            <p>{caseData.feedbackSuggestion1}</p>
            <p>{caseData.feedbackSuggestion2}</p>
            <p>{caseData.feedbackSuggestion3}</p>
          </div>
          <img src={caseData.feedbackSuggImg} alt={caseData.feedbackSuggImgAlt} loading="lazy" className= {`feedback-img ${caseData.feedbackImg3TF ? '' : 'hidden'}`} />
        </div>
        </div>
        {showSectionLine.feedbackLine && <div className="section-line" />}
    </div>
  );
};

export default CaseFeedback;
