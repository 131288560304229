import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseProblem = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.problemSection ? '' : 'hidden'}`}>
    <div className="section">
      <div className="problem-statement col">
          <h4>{caseData.problemHeader}</h4>
          <p>{caseData.problemStatement}</p>
        </div>
        </div>
        {showSectionLine.problemLine && <div className="section-line" />}
    </div>
  );
};

export default CaseProblem;
