import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseSummary = ({ caseData, showSectionLine }) => {
  return (
    <div>
    <div className="section">
      <div className="summary col mb-xl">
        <h3>{caseData.projTitle}</h3>
        <p>{caseData.summary}</p>
      </div>
      <div className="summary row">
        <div className="sm-lst">
          <div className="sm-lst-head">My Role</div>
          <div className="sm-lst-item">{caseData.myRole}</div>
        </div>
        <div className="sm-lst">
          <div className="sm-lst-head">Tools Used</div>
          <div className="sm-lst-item">{caseData.toolList}</div>
        </div>
        <div className="sm-lst">
          <div className="sm-lst-head">Timeline</div>
          <div className="sm-lst-item">{caseData.timeline}</div>
        </div>
        <div className="sm-lst">
          <div className="sm-lst-head">Team</div>
          <div className="sm-lst-item">{caseData.teamList}</div>
        </div>
      </div>
      </div>
      {showSectionLine.summaryLine && <div className="section-line" />}
    </div>
  );
};

export default CaseSummary;
