import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CasePrototype = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.prototypeSection ? '' : 'hidden'}`}>
    <div className="section">
      <div className="section-title">{caseData.ST_Prototype}</div>
        <div className="prototype col mb-xl">
          <h4>{caseData.wireframeTitle}</h4>
          <div className="WFImgWrap">
            <img src={caseData.wireframeImg} alt={caseData.wireframeImgAlt} loading="lazy" className="wireframe-img" />
          </div>
        </div>
        <div className="prototype col">
          <h5>Design Decision</h5>
          <p className="designQ">Q: {caseData.designDecisionQ}</p>
          <p className="designA">A: {caseData.designDecisionA}</p>
          <p>{caseData.designDecisionExplained}</p>
        </div>
        </div>
        {showSectionLine.prototypeLine && <div className="section-line" />}
    </div>
  );
};

export default CasePrototype;
