import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseResearch = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.researchSection ? '' : 'hidden'}`}>
    <div className="section">
      <div className="section-title">{caseData.ST_Researh}</div>
        <div className="research col mb-xl">
          <h4>{caseData.R_GoalTitle}</h4>
          <ul>
            <li>{caseData.researchGoal1}</li>
            <li>{caseData.researchGoal2}</li>
            <li>{caseData.researchGoal3}</li>
            <li>{caseData.researchGoal4}</li>
            <li className={`${caseData.researchGoal5TF ? '' : 'hidden'}`}>{caseData.researchGoal5}</li>
          </ul>
        </div>

        <div className="personaSec col mb-xl">
          
          <div className="personaHeader row">
            <h5>{caseData.persona1Type}</h5>
          </div>
          <div className="row">
            <div className="col Pcol1">
              <div className="personaImgWrap">
                <img src={caseData.personaImg1} alt={caseData.personaImgAlt1} loading="lazy" className="persona-img"/>
              </div>
              <div className="personaDetails col">
                <h6>{caseData.persona1Name}</h6>
                <p>{caseData.persona1Title}</p>
                <p>{caseData.persona1JobLocation}</p>
              </div>
            </div>
            <div className="col Pcol2">
              <div className="aspiration col">
                <h6>{caseData.personaAHeader}</h6>
                <p>{caseData.persona1AspP1}
                <hli>{caseData.persona1AspHp1}</hli>
                {caseData.persona1AspP2}
                <hli>{caseData.persona1AspHp2}</hli>
                {caseData.persona1AspP3}</p>
              </div>
              <div className="frustration col">
                <h6>{caseData.personaFHeader}</h6>
                <ul>
                  <li>{caseData.persona1Frust1}</li>
                  <li>{caseData.persona1Frust2}</li>
                  <li>{caseData.persona1Frust3}</li>
                </ul>
              </div>
              <div className="interest col">
                <h6>{caseData.personaIHeader}</h6>
                <ul>
                  <li>{caseData.persona1Int1}</li>
                  <li>{caseData.persona1Int2}</li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="personaHeader row persona2">
            <h5>{caseData.persona2Type}</h5>
          </div>
          <div className="row">
            <div className="col Pcol1">
              <div className="personaImgWrap">
                <img src={caseData.personaImg2} alt={caseData.personaImgAlt2} loading="lazy" className="persona-img"/>
              </div>
              <div className="personaDetails col">
                <h6>{caseData.persona2Name}</h6>
                <p>{caseData.persona2Title}</p>
                <p>{caseData.persona2JobLocation}</p>
              </div>
            </div>
            <div className="col Pcol2">
              <div className="aspiration">
                <h6>{caseData.personaAHeader}</h6>
                <p>{caseData.persona2AspP1}
                <hli>{caseData.persona2AspHp1}</hli>
                {caseData.persona2AspP2}
                <hli>{caseData.persona2AspHp2}</hli>
                {caseData.persona2AspP3}</p>
              </div>
              <div className="frustration">
                <h6>{caseData.personaFHeader}</h6>
                <ul>
                  <li>{caseData.persona2Frust1}</li>
                  <li>{caseData.persona2Frust2}</li>
                  <li>{caseData.persona2Frust3}</li>
                </ul>
              </div>
              <div className="interest">
                <h6>{caseData.personaIHeader}</h6>
                <p>{caseData.persona2Int1}</p>
                <p>{caseData.persona2Int2}</p>
              </div>
            </div>
          </div>
          
        </div>

        <div className="research col mb-xxl">
          <h5>{caseData.EX_Research}</h5>
          <ul>
            <li>{caseData.existingSys1}</li>
            <li>{caseData.existingSys2}</li>
            <li>{caseData.existingSys3}</li>
            <li className={`${caseData.existingSys4TF ? '' : 'hidden'}`}>{caseData.existingSys4}</li>
          </ul>
        </div>
        <div className="research row double_researchWrap">
          <img src={caseData.researchImg1} alt={caseData.researchImg1Alt} loading="lazy" className={`mb-xl research-img double_research ${caseData.researchImg1TF ? '' : 'hidden'}`}
 />
          <img src={caseData.researchImg2} alt={caseData.researchImg2Alt} loading="lazy" className={`mb-xl research-img double_research ${caseData.researchImg2TF ? '' : 'hidden'}`}
 />
        </div>
        <div className="research col">
          <h4>{caseData.SI_Research}</h4>
        </div>

        <div className="researchQA row">
          <div className="questions">
            <h6>{caseData.ResearchQ1}</h6>
            <div className={`${caseData.QAnumberQ1TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ1}</h2>
              <h6>{caseData.AnswerQ1}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ1TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q1}</li>
                <li>{caseData.bullet2Q1}</li>
                <li>{caseData.bullet3Q1}</li>
                <li className={`${caseData.QAbulletQ1a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q1}</li>
              </ul>
            </div>
          </div>
          <div className="questions">
            <h6>{caseData.ResearchQ2}</h6>
            <div className={`${caseData.QAnumberQ2TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ2}</h2>
              <h6>{caseData.AnswerQ2}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ2TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q2}</li>
                <li>{caseData.bullet2Q2}</li>
                <li>{caseData.bullet3Q2}</li>
                <li className={`${caseData.QAbulletQ2a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q2}</li>
              </ul>
            </div>
          </div>
          <div className="questions">
            <h6>{caseData.ResearchQ3}</h6>
            <div className={`${caseData.QAnumberQ3TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ3}</h2>
              <h6>{caseData.AnswerQ3}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ3TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q3}</li>
                <li>{caseData.bullet2Q3}</li>
                <li>{caseData.bullet3Q3}</li>
                <li className={`${caseData.QAbulletQ3a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q3}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="research row">
          <img src={caseData.visualDataImg1} alt={caseData.visualDataImg1Alt} loading="lazy" className={`visual-data ${caseData.visualDataImg1TF ? '' : 'hidden'}`} />
        </div>

        <div className={`researchQA row rQA2 ${caseData.qaRow2TF ? '' : 'hidden'}`}>
          <div className="questions">
            <h6>{caseData.ResearchQ4}</h6>
            <div className={`${caseData.QAnumberQ4TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ4}</h2>
              <h6>{caseData.AnswerQ4}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ4TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q4}</li>
                <li>{caseData.bullet2Q4}</li>
                <li>{caseData.bullet3Q4}</li>
                <li className={`${caseData.QAbulletQ4a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q4}</li>
              </ul>
            </div>
          </div>
          <div className="questions">
            <h6>{caseData.ResearchQ5}</h6>
            <div className={`${caseData.QAnumberQ5TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ5}</h2>
              <h6>{caseData.AnswerQ5}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ5TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q5}</li>
                <li>{caseData.bullet2Q5}</li>
                <li>{caseData.bullet3Q5}</li>
                <li className={`${caseData.QAbulletQ5a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q5}</li>
              </ul>
            </div>
          </div>
          <div className="questions">
            <h6>{caseData.ResearchQ6}</h6>
            <div className={`${caseData.QAnumberQ6TF ? '' : 'hidden'}`}>
              <h2 className="Anumber">{caseData.NumberQ6}</h2>
              <h6>{caseData.AnswerQ6}</h6>
            </div>
            <div className={`bullets ${caseData.QAbulletsQ6TF ? '' : 'hidden'}`}>
              <ul>
                <li>{caseData.bullet1Q6}</li>
                <li>{caseData.bullet2Q6}</li>
                <li>{caseData.bullet3Q6}</li>
                <li className={`${caseData.QAbulletQ6a4TF ? '' : 'hidden'}`}>{caseData.bullet4Q6}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="research col">
          <img src={caseData.visualDataImg2} alt={caseData.visualDataImg2Alt} loading="lazy" className={`visual-data ${caseData.visualDataImg2TF ? '' : 'hidden'}`} />
          <img src={caseData.visualDataImg3} alt={caseData.visualDataImg3Alt} loading="lazy" className={`visual-data ${caseData.visualDataImg3TF ? '' : 'hidden'}`} />
        </div>
        <div className={`research col mb-xl ${caseData.affinityDiagramTF ? '' : 'hidden'}`}>
          <h4>{caseData.ST_AF_Diagram}</h4>
          <img src={caseData.affinityImg} alt={caseData.affinityImgAlt} loading="lazy" className="affinity-diagram" />
        </div>
        <div className={`research col mb-xl ${caseData.insightsTF ? '' : 'hidden'}`}>
          <h4>{caseData.R_Insights}</h4>
          <h5>{caseData.R_Pain}</h5>
            <div className="research row painpoints">
              <div className="user-picImgWrap">
                <img src={caseData.insightImg1} alt={caseData.insightImg1Alt} loading="lazy" className="user-pic" />
              </div>
              <p>{caseData.insightQuote1}</p>
            </div>
            <div className="research row painpoints">
              <div className="user-picImgWrap">
                <img src={caseData.insightImg2} alt={caseData.insightImg2Alt} loading="lazy" className="user-pic" />
              </div>
              <p>{caseData.insightQuote2}</p>
            </div>
            <div className="research row painpoints">
              <div className="user-picImgWrap">
                <img src={caseData.insightImg3} alt={caseData.insightImg3Alt} loading="lazy" className="user-pic" />
              </div>
              <p>{caseData.insightQuote3}</p>
            </div>
        </div>
        <div className="research findings col">
          <h4>{caseData.R_Findings}</h4>
            <div className="research row">
              <h6>{caseData.R_Finding1}</h6>
              <p>{caseData.findings1}</p>
            </div>
            <div className="research row">
              <h6>{caseData.R_Finding2}</h6>
              <p>{caseData.findings2}</p>
            </div>
            <div className="research row">
              <h6>{caseData.R_Finding3}</h6>
              <p>{caseData.findings3}</p>
            </div>
        </div>
        </div>
        {showSectionLine.researchLine && <div className="section-line" />}
    </div>
  );
};

export default CaseResearch;
