import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseDesign = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.DesignSection ? '' : 'hidden'}`}>
      <div className="section">
      <div className="section-title">{caseData.ST_Design}</div>
        <div className={`design col mb-xl ${caseData.visualIdentityTF ? '' : 'hidden'}`} >
          <h5 className="nm">{caseData.VI_Title}</h5>
          <img src={caseData.visualIdImg} alt={caseData.visualIdImgAlt} loading="lazy" className={`visual-identity ${caseData.visualIdentityImgTF ? '' : 'hidden'}`} />
        </div>

        <div className="design col">
          <h4 className="mb-lg">{caseData.DS_Title}</h4>
          
          <div className="design featureRow">
            <div className="feature">
              <div className="featureText">
                <h6>{caseData.featureNumber1}</h6>
                <h5>{caseData.feature1}</h5>
                <p>{caseData.feature1Description}</p>
              </div>
              <div className={`featurePic row ${caseData.featurePicsTF ? '' : 'hidden'}`}>
                <img src={caseData.feature1Img1} alt={caseData.feature1Img1Alt} loading="lazy" className={`feature-img ${caseData.feature1Img1TF ? '' : 'hidden'}`}/>
              </div>
            </div>
            <div className="feature">
              <div className="featureText">
              <h6>{caseData.featureNumber2}</h6>
              <h5>{caseData.feature2}</h5>
              <p>{caseData.feature2Description}</p>
              </div>
              <div className={`featurePic row ${caseData.featurePicsTF ? '' : 'hidden'}`}>
                <img src={caseData.feature2Img1} alt={caseData.feature2Img1Alt} loading="lazy" className={`feature-img ${caseData.feature2Img1TF ? '' : 'hidden'}`}/>
                <img src={caseData.feature2Img2} alt={caseData.feature2Img2Alt} loading="lazy" className={`feature-img ${caseData.feature2Img2TF ? '' : 'hidden'}`}/>
              </div>
            </div>
            <div className="feature">
              <div className="featureText">
                <h6>{caseData.featureNumber3}</h6>
                <h5>{caseData.feature3}</h5>
                <p>{caseData.feature3Description}</p>
              </div>
              <div className={`featurePic row ${caseData.featurePicsTF ? '' : 'hidden'}`}>
                <img src={caseData.feature3Img1} alt={caseData.feature3Img1Alt} loading="lazy" className={`feature-img ${caseData.feature3Img1TF ? '' : 'hidden'}`}/>
              </div>
            </div>
            <div className="feature">
              <div className="featureText">
                <h6>{caseData.featureNumber4}</h6>
                <h5>{caseData.feature4}</h5>
                <p>{caseData.feature4Description}</p>
              </div>
              <div className={`featurePic row ${caseData.featurePicsTF ? '' : 'hidden'}`}>
                <div className="fpics">
                  <img src={caseData.feature4Img1} alt={caseData.feature4Img1Alt} loading="lazy" className={`feature-img ${caseData.feature4Img1TF ? '' : 'hidden'}`}/>
                  <img src={caseData.feature4Img2} alt={caseData.feature4Img2Alt} loading="lazy" className={`feature-img ${caseData.feature4Img2TF ? '' : 'hidden'}`}/>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className={`design col mb-xl ${caseData.userJourneyTF ? '' : 'hidden'}`}>
          <h4 className="nm">{caseData.UJ_Title}</h4>
          <img src={caseData.userJourneyImg} alt={caseData.userJourneyImgAlt} loading="lazy" className="user-journey" />
        </div>
        <div className={`design col ${caseData.finalDesignTF ? '' : 'hidden'}`}>
          <h3 className="nm">{caseData.FD_Title}</h3>
          <img src={caseData.finalImg} alt={caseData.finalImgAlt} loading="lazy" className={`final-design ${caseData.FDImgTF ? '' : 'hidden'}`}/>
          <div className={`finalVidWrap ${caseData.FDVidTF ? '' : 'hidden'}`}>
            <img src="/assets/RealityCheque/iPhone14FrameS.png" loading="lazy"  className="finalVidFrame" alt=""/>
            <video  className="finalVid" controls autoplay>
              <source src={caseData.finalVid} alt={caseData.finalVidAlt} loading="lazy" type="video/mp4"/>  
            </video>
          </div>
        </div>
        <div className={`challengeSection ${caseData.challengeSecTF ? '' : 'hidden'}`}>
          <div className="design row">
            <h5 className="mb-md">{caseData.challengeTitle}</h5>
          </div>
          <div className="design row">
              <h6>Challenges</h6>
              <h6>Solutions</h6>
              </div>
              <div className="challengeListWrap">
                <ul className="p-xs row">
                  <li className="challenge">{caseData.challenge1}</li>
                  <li className="solution">{caseData.solution1}</li>
                </ul><ul className="p-xs row">
                  <li className="challenge">{caseData.challenge2}</li>
                  <li className="solution">{caseData.solution2}</li>
                </ul><ul className={`p-xs row ${caseData.challengeBul3TF ? '' : 'hidden'}`}>
                  <li className="challenge">{caseData.challenge3}</li>
                  <li className="solution">{caseData.solution3}</li>
                </ul><ul className={`p-xs row ${caseData.challengeBul4TF ? '' : 'hidden'}`}>
                  <li className="challenge">{caseData.challenge4}</li>
                  <li className="solution">{caseData.solution4}</li>
                </ul>
              </div>
          </div>

      </div>
      {showSectionLine.designLine && <div className="section-line" />}
    </div>
  );
};

export default CaseDesign;
