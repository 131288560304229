import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseOverview = ({ caseData, showSectionLine }) => {
  return (
    <div className={`${caseData.overviewSection ? '' : 'hidden'}`}>
      <div className="section">
        <div className="section-title">Overview</div>
          <div className="overview col mb-xl">
            <h4>{caseData.projPrompttitle}</h4>
            <p>{caseData.projPrompt}</p>
          </div>
          <div className="overview col mb-xl">
            <h5>Design Process</h5>
            <img src={caseData.designImg} alt={caseData.designImgAlt} loading="lazy" className="process-img" />
          </div>
          <div className="overview row mb-xl">
            <div className="Olist md-lst">
              <h6>Target Users</h6>
              <ul>
                <li>{caseData.tarUsers1}</li>
                <li className={`${caseData.tarUser2TF ? '' : 'hidden'}`}>{caseData.tarUsers2}</li>
                <li className={`${caseData.tarUser3TF ? '' : 'hidden'}`}>{caseData.tarUsers3}</li>
              </ul>
            </div>
            <div className="Olist overview md-lst">
              <h6>Research Methods</h6>
              <ul>
                <li>{caseData.researchMethods1}</li>
                <li>{caseData.researchMethods2}</li>
                <li className={`${caseData.researchM3TF ? '' : 'hidden'}`}>{caseData.researchMethods3}</li>
              </ul>
            </div>
            </div>
            <div className="overview hmw col">
              <h6>How Might We...</h6>
              <ul>
                <li>{caseData.HMWQuestion1}</li>
                <li>{caseData.HMWQuestion2}</li>
                <li className={`${caseData.hmw3TF ? '' : 'hidden'}`}>{caseData.HMWQuestion3}</li>
              </ul>
            </div>
          </div>
          {showSectionLine.overviewLine && <div className="section-line" />}
    </div>
  );
};

export default CaseOverview;
