import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CasePage from './pages/CasePage';
import AboutPage from './pages/AboutPage';
import './components/Global.css';
import './components/scale.css';
import caseData from './components/case/case-data/CaseData';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Helper function to generate case study routes
const generateCaseRoutes = () => {
  return caseData.map((caseItem) => (
    <Route
      key={caseItem.id}
      path={`/portfolio/${caseItem.id}`}
      element={<CasePage caseData={caseItem} />}
    />
  ));
};

const App = () => {
  const caseRoutes = generateCaseRoutes();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {caseRoutes}
        <Route path="/work" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </Router>
  );
};

export default App;
