import React from 'react';
import { Helmet } from 'react-helmet';
import NavBar from '../components/Navbar';
import Footer from '../components/footer';
import '../components/Global.css';
import '../components/About.css';

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Scott Tollerton | Front-End and Interaction Designer</title>
        <meta name="description" content="Explore Scott Tollerton's UX and interaction design portfolio." />
        <meta property="og:title" content="About Scott Tollerton | Front-End and Interaction Designer" />
        <meta property="og:description" content="Explore Scott Tollerton's UX and interaction design portfolio." />
        <meta property="og:image" content="/assets/Misc/PortfolioMD.png" />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/assets/Misc/favicon.ico" type="image/x-icon" />
      </Helmet>
      <NavBar />
      <div className="row nm">
        <div className="stripe"></div>
        <AboutContent />
      </div>
      <Footer />
    </div>
  );
};

const AboutContent = () => {
  return (
    <div className="aboutWrapper col">
      <div className="row">
        <AboutMe />
        <AboutImage />
      </div>
      <div className="aboutMeBonus">
        <AboutList title="Areas of Expertise" items={['Web Design', 'User Centered Design', 'UX Design', 'Accessibility', 'UX Research', 'Product Design', '... and more!']} />
        <AboutList title="I'm Working On" items={['React.js', 'Service Design', 'Node.js']} />
        <AboutList title="Personal Interests" items={['Movies', 'Conventions', 'Board Games', 'The Natural World', 'Video Games']} />
      </div>
    </div>
  );
};

const AboutMe = () => {
  return (
    <div className="aboutMe">
      <div className="row">
        <h4 className="aboutTitle"><span>Get to know more&nbsp;</span><span className="highlight">about Scott</span></h4>
      </div>
      <div className="aboutBody">
        <p>I'm Scott Tollerton, a front-end web-app developer and interaction designer. I specialize in creating engaging and intuitive user interfaces that deliver positive experiences.</p>
        <p>I have designed and developed both intuitive software and hardware user interfaces, and continue to seek new design challenges. I graduated from the Sheridan College Honours Interaction Design program, and successfully competed in several hackathons where our entries were finalists or winners.</p>
        <p>I understand the business value that can be created through elegant user interface designs. I also believe UX projects succeed when the goals are well understood, they display a sound understanding of the user, and avoid costly mistakes through research, process design and testing.</p>
      </div>
    </div>
  );
};

const AboutImage = () => {
  return (
    <div className="aboutImgWrap">
      <img className="aboutImg" src={process.env.PUBLIC_URL + '/assets/Misc/profile-pic.png'} loading="lazy" alt="Scott Tollerton" />
    </div>
  );
};

const AboutList = ({ title, items }) => {
  return (
    <div className="bonusList">
      <h6 className="bonusTitle mb-md">{title}</h6>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default About;
