import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar';
import Intro from '../components/Intro';
import WorkSection from '../components/WorkSection';
import Card from '../components/Card';
import Footer from '../components/footer';

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Scott Tollerton | Front-End and Interaction Designer - Portfolio</title>
        <meta name="description" content="Explore Scott Tollerton's UX and interaction design portfolio." />
        <meta property="og:title" content="Scott Tollerton | Front-End and Interaction Designer - Portfolio" />
        <meta property="og:description" content="Explore Scott Tollerton's UX and interaction design portfolio." />
        <meta property="og:image" content="/assets/Misc/PortfolioMD.png" />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/assets/Misc/favicon.ico" type="image/x-icon" />
      </Helmet>
      <NavBar />
      <div className="row nm">
        <div className="stripe"></div>
        <div className="col center">
        <Intro />
        <WorkSection> 
          <Link className="mb-xl" to="/portfolio/Flare">
            <Card
              title="Flare"
              description="Created an inexpensive early wildfire detection prototype to provide more timely information."
              imageSrc="/assets/Flare/FlareCardImg.png"
              imageAlt="Flare Wildfire Detection App"
              tag1="UX Design"
              tag2="UI Design"
              tag3="Product Design"
            />
          </Link>
          <Link className="mb-xl" to="/portfolio/Forj">
            <Card
              title="Event Platform - Forj"
              description="Designed user experience for virtual event platform, including meet accessibility."
              imageSrc="/assets/Forj/FORJ_card.png"
              imageAlt="Forj Front End Design"
              tag1="Web Design"
              tag2="UX Design"
              tag3="Accessibility"
            />
          </Link>
          <Link className="mb-xl" to="/portfolio/Reality-Cheque">
            <Card
              title="Reality Cheque"
              description="Designed an augmented reality app for mobile devices to show how saving money grows over time."
              imageSrc="/assets/RealityCheque/RealityChequeCard.png"
              imageAlt="Reality Cheque AR App"
              tag1="UX Design"
              tag2="UI Design"
              tag3="Graphic Design"
            />
          </Link>
          <Link className="mb-xl" to="/portfolio/GameDev-UX">
            <Card
              title="GameDev UX"
              description="Created a game developers UX toolbox respository for game development processes and methods."
              imageSrc="/assets/GameDevUX/GameDevCard.png"
              imageAlt="UX GameDev Web App"
              tag1="UX Design"
              tag2="Web Design"
              tag3="Design Thinking"
            />
          </Link>
        </WorkSection>
        </div>
        </div>
      <Footer />
    </div>
  );
};

export default HomePage;
