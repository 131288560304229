import React from 'react';
import './WorkSection.css';

const SectionRow = (props) => {
  return (
    <section className="work-section">
      <h2 className="">Selected Work</h2>
      <div className="section-line mb-xl sel-wk"></div>
      <div className="cards-wrapper">
          {props.children}
        </div>
    </section>
  );
};

export default SectionRow;

