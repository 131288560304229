import React from 'react';
import './Card.css';

const Card = (props) => {
  return (
      <div className="card">
        <div className="cardImgWrap">
          <img src={props.imageSrc} alt={props.imageAlt} loading="lazy" className="card-image" />
        </div>
        <div className="card-info">
          <div className="card-title">
              <h3>{props.title}</h3>
          </div>
        <div className="tag-row">
            {props.tag1 && <div className="card-tag">{props.tag1}</div>}
            {props.tag2 && <div className="card-tag">{props.tag2}</div>}
            {props.tag3 && <div className="card-tag">{props.tag3}</div>}
            {props.tag4 && <div className="card-tag">{props.tag4}</div>}
        </div>
        <div className="card-description">
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
