import React from 'react';
import '../Global.css';
import '../Dark.css';
import '../Case.css';

const CaseImpact = ({ caseData, showSectionLine }) => {
  return (
    <div className={` ${caseData.impactSection ? '' : 'hidden'}`}>
    <div className="section">
      <div className="impact row">
          <div className="impact col">
            <h4>{caseData.impactHeader}</h4>
            <p>{caseData.impactStatement}</p>
          </div>
          <img src={caseData.impactImg} alt={caseData.impactImgAlt} loading="lazy" className={`impact-img ${caseData.impactImgTF ? '' : 'hidden'}`}  />
        </div>
        </div>
        {showSectionLine.impactLine && <div className="section-line" />}
    </div>
  );
};

export default CaseImpact;
