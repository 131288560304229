import React from 'react';
import './Global.css';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent">
        <FooterText />
        <FooterLinks />
      </div>
    </div>
  );
};

const FooterText = () => {
  return (
    <div className="footerText">
      <h6 className="footerMsg">Nice to see you here!</h6>
      <div className="footerNote">
        <a href="mailto:stollerton@gmail.com">stollerton@gmail.com</a>
        <p>This website is best viewed on a desktop</p>
        <p>Built with React.js</p>
      </div>
    </div>
  );
};

const FooterLinks = () => {
  return (
    <div className="footerLinks">
      <a href="mailto:stollerton@gmail.com">Email</a>
      <a href="/assets/Scott Tollerton - 2023 Resume.pdf" target="_blank" rel="noopener noreferrer">Resumé</a>
      <a href="https://www.linkedin.com/in/stollerton/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
    </div>
  );
};

export default Footer;
