const caseData = [
    {
      id: `Flare`,
      //Splash Image at top of Page
      caseSplashImg: `/assets/Flare/Flare.png`,
      caseSplashImgAlt: `Flare Detect Protect Preserve`,

      //Summary Section
      projTitle: `Flare`,
      summary: `Designed as an inexpensive wildfire detection solution, Flare's interconnected sensor network is able to detect wildfires before existing methods can spot a fire, giving firefighters an earlier warning and more time to contain developing wildfires.`,
      myRole: `Product Design, UX Design, UI Design, and Branding`,
      teamList: `Scott Tollerton, Aidan Philpott, Divyanshi Srivastava, and Jerry Tan`,
      timeline: `36 Hours`,
      toolList: `Figma, React, Google Map API, Firebase, Arduino, ESP32 and Sensors `,
      showSectionLine: {
        summaryLine: true,
        overviewLine: true,
        researchLine: true,
        designLine: true,
        problemLine: true,
        impactLine: true,
        prototypeLine: true,
        feedbackLine: false,
      },
      caseStyle: {
        backgroundColor: '#1f1f1f',
      },

      //Overview Section
      overviewSection: true,
      tarUser2TF: true,
      tarUser3TF: false,
      researchM3TF: true,
      hmw3TF: true,
      projPrompttitle: `The Rising Rate of Wildfires`,
      projPrompt: `Scientists are warning that 50% of all woodlands worldwide will be devastated by wildfires by 2030. With forests becoming more dense and the number of wildfires increasing, current forestry management programs are struggling to cope.`,
      designImg: `/assets/flare/designprocess.svg`,
      designImgAlt: `Flare Design Process`,
      tarUsers1: `Wildfire fighters and evacuation crews, needing current fire and geolocation data, both when at base and when engaged fighting fires. `,
      tarUsers2: `Forest management personnel examining gathered data on wildfires.`,
      tarUsers3: ``,
      researchMethods1: `Competitor Research`,
      researchMethods2: `External Secondary Research`,
      researchMethods3: `Concept Research`,

      personaAHeader: `Aspirations`,
      personaFHeader: `Frustrations`,
      personaIHeader: `Interests`,
      persona1Type: `Persona - Park Personel`,
      personaImg1: `/assets/Flare/JackHarris2.jpg`,
      personaImgAlt1: `Park Service Persona`,
      persona1Name: `Jack Harris`,
      persona1Title: `National Park Service`,
      persona1JobLocation: `Yosemite National Park Ranger`,
      persona1AspP1: `"I want to `,
      persona1AspHp1: `protect the wilderness `,
      persona1AspP2: `and keep the impact on the natural world to the minimum."`,
      persona1AspHp2: ``,
      persona1AspP3: ``,
      persona1Frust1: `When forest fires are detected too late to prevent rapid spread.`,
      persona1Frust2: `When the detection system fails.`,
      persona1Frust3: ``,
      persona1Int1: `Prefers the peace of nature found in the parks.`,
      persona1Int2: `Seeking a replacement for the existing system that did not provide adequate warning.`,

      persona2Type: `Persona - Fire Fighter`,
      personaImg2: `/assets/Flare/KarenMarshall2.jpg`,
      personaImgAlt2: `Fire Fighter Persona`,
      persona2Name: `Karen Marshall`,
      persona2Title: `Wildland Fire Fighter`,
      persona2JobLocation: `Bureau of Land Management`,
      persona2AspP1: `"Keeping my squads safe by `,
      persona2AspHp1: `dealing with fewer and smaller fires`,
      persona2AspP2: `."`,
      persona2AspHp2: ``,
      persona2AspP3: ``,
      persona2Frust1: `When information about new and spreading fires is delayed.`,
      persona2Frust2: ``,
      persona2Frust3: ``,
      persona2Int1: `Finding better real time display systems to visualize the spread of forest fires.`,
      persona2Int2: ``,

      HMWQuestion1: `How might we detect and contain wildfires sooner?`,
      HMWQuestion2: `How might we update key stakeholders in real time?`,
      HMWQuestion3: `How might we present gathered data in an easy to use format?`,

      //Research Section
      researchSection: true,
      researchImg1TF: false,
      researchImg2TF: false,
      visualDataImg1TF: true,
      visualDataImg2TF: true,
      visualDataImg3TF: false,
      affinityDiagramTF: false,
      problemSection: true,

      QAnumberQ1TF: false,
      QAbulletsQ1TF: true,
      QAnumberQ2TF: true,
      QAbulletsQ2TF: false,
      QAnumberQ3TF: true,
      QAbulletsQ3TF: false,
      QAnumberQ4TF: true,
      QAbulletsQ4TF: false,
      QAnumberQ5TF: false,
      QAbulletsQ5TF: true,
      QAnumberQ6TF: false,
      QAbulletsQ6TF: true,

      QAbulletQ1a4TF: false,
      QAbulletQ2a4TF: false,
      QAbulletQ3a4TF: false,
      QAbulletQ4a4TF: false,
      QAbulletQ5a4TF: true,
      QAbulletQ6a4TF: false,

      researchGoal5TF: true,
      existingSys4TF: true,
      qaRow2TF: true,
      insightsTF: true,

      ST_Researh: `Research`,
      R_GoalTitle: `Exploration Goals`,
      researchGoal1: `Identify existing fire detection solutions and their effectiveness.`,
      researchGoal2: `Discover environmentally friendly solutions for fire detection.`,
      researchGoal3: `Analyze research assessing new methods of forest fire detection.`,
      researchGoal4: `Examine real time presentation options for candidate fire detection models.`,
      researchGoal5: `Identify candidate fire detection models to prototype.`,

      EX_Research: `Fire Detection Today`,
      existingSys1: `Satelite imaging fire detection`,
      existingSys2: `Thermal infrared imaging fire detection`,
      existingSys3: `CCTV visual detection systems`,
      existingSys4: `Staffed firewatch towers`,
      researchImg1: ``,
      researchImg1Alt: ``,
      researchImg2: ``,
      researchImg2Alt: ``,

      SI_Research: `Understanding the Situation`,

      ResearchQ1: `What are the ways we can detect fires?`,
      NumberQ1: ``,
      AnswerQ1: ``,
      bullet1Q1: `Video / CCTV Systems`,
      bullet2Q1: `IR Systems`,
      bullet3Q1: `Satellites`,
      bullet4Q1: ``,

      ResearchQ2: `Why are forest fires getting worse?`,
      NumberQ2: `1150%`,
      AnswerQ2: `more tree density in forest acres – increasing the risk of wildfire`,
      bullet1Q2: ``,
      bullet2Q2: ``,
      bullet3Q2: ``,
      bullet4Q2: ``,

      ResearchQ3: `Are wild fires getting more frequent?`,
      NumberQ3: `400%`,
      AnswerQ3: `increase in frequency of wildfires in the U.S. since 1970`,
      bullet1Q3: ``,
      bullet2Q3: ``,
      bullet3Q3: ``,
      bullet4Q3: ``,
      
      visualDataImg1: `/assets/Flare/Forest-Acres-Burned.svg`,
      visualDataImg1Alt: `Forest Acres Burned Data`,

      ResearchQ4: `How expensive is it to fight wildfires?`,
      NumberQ4: `$2 Billion`,
      AnswerQ4: `was spend in California in 2018 alone.`,
      bullet1Q4: ``,
      bullet2Q4: ``,
      bullet3Q4: ``,
      bullet4Q4: ``,

      ResearchQ5: `What are some of the effects of a wildfire?`,
      NumberQ5: ``,
      AnswerQ5: ``,
      bullet1Q5: `Increased temperature and C02`,
      bullet2Q5: `Compromising water supplies`,
      bullet3Q5: `Severly damaging infrastructure`,
      bullet4Q5: `Destroying wildlife`,

      ResearchQ6: `Are there experimental fire detection systems?`,
      NumberQ6: ``,
      AnswerQ6: ``,
      bullet1Q6: `Sensor Networks`,
      bullet2Q6: `Laser imaging detectors`,
      bullet3Q6: `Spectroscopy based detectors`,
      bullet4Q6: ``,

      visualDataImg2: `/assets/Flare/Wildfire-Financial-Loss.svg`,
      visualDataImg2Alt: `Wildfire Financial Loss Data`,
      visualDataImg3: ``,
      visualDataImg3Alt: ``,
      ST_AF_Diagram: ``,
      affinityImg: ``,
      affinityImgAlt: ``,
      R_Insights: `Insights Gained`,
      R_Pain: `Pain Points`,
      insightImg1: `/assets/Flare/Forestfires.png`,
      insightImg1Alt: `Forest Fire Graphic`,
      insightQuote1: `Visual and infrared systems are unable to detect fires early on, needing them to be large enough to register.`,
      insightImg2: `/assets/Flare/FireVisuals.png`,
      insightImg2Alt: `Detecting Fires Graphic`,
      insightQuote2: `Data can be hard to read or spot depending on the interface software being fed the detection data.`,
      insightImg3: `/assets/Flare/fireshields.png`,
      insightImg3Alt: `Fire Protection Graphic`,
      insightQuote3: `There's no access to the data in real time on site without setting up a base camp, and even then it isn't truly mobile.`,
      R_Findings: `A Worsening Picture`,
      R_Finding1: `01`,
      findings1: `Earlier detection reduces the size and cost of fighting these fires, and the risks to those fighting these fires. However, standard visual and infrared wide area detection solutions only react to sizable burns, and can be unreliable or fail to provide early warnings.`,
      R_Finding2: `02`,
      findings2: `Wildfires are becoming more frequent and are getting larger. The cost of fighting these fires is rapidly growing and those who fight these fires need better fire detection tools now.`,
      R_Finding3: `03`,
      findings3: `Experimental fire detection systems are currently expensive or limited in performance. However, sensor networks and real time information systems warranted additional consideration.`,

      //Problem Statement
      problemHeader: `A Burning Problem`,
      problemStatement: `There is currently no system that provides reliable wide area early detection of wildfires at a reasonable cost. Moreover the data provided by existing systems is limited until after a fire has only grown in size, and can be difficult to access and interpret.`,

      //Prototype Section
      prototypeSection: true,
      ST_Prototype: `Prototype`,
      wireframeTitle: `Detecting the Fires`,
      wireframeImg: `/assets/Flare/mockup2.png`,
      wireframeImgAlt: `Flare App and Systems Mockups`,
      designDecisionQ: `What is the most cost effective solution that provides the earliest warning possible? `,
      designDecisionA: `Onsite ground sensors with a wireless mesh network.`,
      designDecisionExplained: `This solution provides a cost effective early forest fire warning solution. The network enables data to be gathered centrally and made available to the different classes of users via a mobile and web app. Interactive data points and visualizations convey the status of the forest and the presence of possible wildfires in real time. Data transferred is stored to support both real time and historical data reviews.`,

      //Design Section
      DesignSection: true,
      visualIdentityImgTF: true,
      visualIdentityTF: true,
      feature1Img1TF: true,
      feature2Img1TF: true,
      feature2Img2TF: true,
      feature3Img1TF: true,
      designRow4TF: true,
      feature4Img1TF: true,
      feature4Img2TF: false,
      userJourneyTF: true,
      finalDesignTF: true,
      FDImgTF: true,
      FDVidTF: false,
      featurePicsTF: true,
      challengeSecTF: true,
      challengeBul3TF: true,
      challengeBul4TF: true,

      ST_Design: `Design`,
      VI_Title: `Giving it Style`,
      visualIdImg: `/assets/Flare/Visual-Identity.png`,
      visualIdImgAlt: `Flare Visual Identity`,
      DS_Title: `Dectect and Communicate`,
      featureNumber1: `01`,
      featureNumber2: `02`,
      featureNumber3: `03`,
      featureNumber4: `04`,
      feature1: `Real Time Tracking and Visualizations`,
      feature1Description: `Real time wildfire event tracking using a dynamic heat map displayed via a web app. A simple to use interface built using the Google Maps API shows the network of sensor nodes, their status and location.`,
      feature1Img1: `/assets/Flare/flaremobile1+2s.png`,
      feature1Img1Alt: `Flare on Mobile Devices`,
      feature1Caption: ``,
      feature2: `Data Transfer and Storage`,
      feature2Description: `Information captured by the sensors is concurrently written to a Firebase database and made accessible via a web app UI in real time using React and the Google Maps API.`,
      feature2Img1: `/assets/Flare/cloud.svg`,
      feature2Img1Alt: `Flare Cloud Storage Graphic`,
      feature2Caption1: ``,
      feature2Img2: ``,
      feature2Img2Alt: ``,
      feature2Caption2: ``,
      feature3: `ESP32 Sensor Mesh Network`,
      feature3Description: `ESP32 microprocessors control volatile organic compounds, CO2 and temperature sensors as part of a Bluetooth and WiFi wireless mesh communications network.`,
      feature3Img1: `/assets/Flare/SensorDiagram.png`,
      feature3Img1Alt: `Flare Sensor Network Diagram`,
      feature3Caption: ``,
      feature4: `Environmentally Friendly and Cost Effective`,
      feature4Description: `The use of long-lived, abuse tolerant and low toxicity LiFePO4 batteries in the sensor packages lowers the per node cost, supports airdrop deployments, and is environmentally friendly when compared to other solutions.`,
      feature4Img1: `/assets/Flare/EcoBattery2.png`,
      feature4Img1Alt: `Eco Friendly Battery Graphic`,
      feature4Caption1: ``,
      feature4Img2: ``,
      feature4Img2Alt: ``,
      feature4Caption2: ``,
      UJ_Title: `Performance vs. Competing Solutions`,
      userJourneyImg: `/assets/Flare/CompPerformance.png`,
      userJourneyImgAlt: `Competitor Performance Analysis Charts`,
      FD_Title: `A Wildfire Detection Solution`,
      finalImg: `/assets/Flare/final.png`,
      finalImgAlt: `Flare Design Solution`,
      finalVid:``,
      finalVidAlt:``,
      challengeTitle:`Innovating Detection Solutions`,
      challenge1:`Identify a cost effective, digital early warning solution that could be deployed widely beside existing firewatch towers, and satellite, thermal, and video detection systems.`,
      solution1:`A mesh network of sensors using microprocessors that support wireless communications. Sensors respond to volatile organic compounds, CO2 and temperature changes.`,
      challenge2:`Provide real time wildfire tracking and visualizations for responders.`,
      solution2:`A mobile web app that displays dynamic heat maps. Sensor location, status and data points presented using React and the Google Maps API.`,
      challenge3:`Transfer and store sensor data to support real time wildfire tracking and analysis.`,
      solution3:`Information captured concurrently written to a Firebase database for processing and display via the app in real time.`,
      challenge4:`Minimize the environmental impact of the solution.`,
      solution4:`Equip sensors with long-lived, less toxic, LiFePO4 batteries.`,

      //Impact Section
      impactSection: true,
      impactImgTF: true,
      impactHeader: `A Prototype Complete`,
      impactStatement: `A new, environmentally friendly, deployable wildfire detection system that provides early detection and displays sensor data in real time to those fighting forest fires and managing our forests was prototyped.`,
      impactImg: `/assets/Flare/FireAlerts.png`,
      impactImgAlt: `Fire Detected Graphic`,

      //Feedback Section
      feedbackSection: false,
      feedbackrow1TF: false,
      feedbackImg1TF: false,
      feedbackrow2TF: false,
      feedbackImg2TF: false,
      feedbackrow3TF: false,
      feedbackImg3TF: false,
      ST_UserFeedback: ``,
      UT_Feedback_Title: ``,
      testFeedback1: ``,
      testFeedback2: ``,
      testFeedback3: ``,
      testFeedback4: ``,
      testFeedback5: ``,
      UT_Advantages_Title: ``,
      feedbackImg: ``,
      feedbackImgAlt: ``,
      feedbackAdv1: ``,
      feedbackAdv2: ``,
      feedbackAdv3: ``,
      feedbackAdv4: ``,
      feedbackAdv5: ``,
      feedbackAdvImg: ``,
      feedbackAdvImgAlt: ``,
      UT_Suggestions_Title: ``,
      feedbackSuggestion1: ``,
      feedbackSuggestion2: ``,
      feedbackSuggestion3: ``,
      feedbackSuggImg: ``,
      feedbackSuggImgAlt: ``,

      //Reflection Section
      reflectionSection: true,
      ST_Reflection: `Reflection`,
      takeawaysTitle: `Successful Prototype, Promising Future`,
      reflection1: `The demonstrator solution of prototype sensors, along with a mobile web app, met the primary design goals of providing real time wildfire information. While practical issues around scaling the solution using Python, and enhancing the user interface were beyond the scope of the 36 hour Santa Barbara hackathon, a roadmap was proposed to address product launch challenges.`,
      reflection2: `The University of California, Santa Barbara expressed interest in the team continuing the project. Further logistic obstacles remain for developing Flare into a full product. How would it obtain initial funding? Where would the sensors be manufactured? What would deployment and maintenance cost? If pursued to being fully realized, I would look forward to tackling those challenges.`,
      reflection3: ``,

    },
    {
      id: `Reality-Cheque`,
      //Splash Image at top of Page
      caseSplashImg: `/assets/RealityCheque/RCintro-nobg.png`,
      caseSplashImgAlt: `Reality Cheque See the Value of Starting Small`,
      showSectionLine: {
        summaryLine: true,
        overviewLine: true,
        researchLine: true,
        designLine: true,
        problemLine: true,
        impactLine: true,
        prototypeLine: true,
        feedbackLine: false,
      },
      caseStyle: {
        background: 'linear-gradient(to bottom right, #e5d7a8, #e5d8a0, #e4d998, #e2da90, #e0db88)',
        backdropFilter: 'blur(4px)',
      },

      //Summary Section
      projTitle: `Reality Cheque`,
      summary: `Reality Cheque is an augmented reality app that superimposes dynamic financial outcomes on a user’s view of the real world, inspiring millennials to pursue financial responsibility and independence. With integrated social media, users can share the financial concepts presented, and the potential savings generated with friends.`,
      myRole: `2D Graphic Design, UI Design, UX Design, Concept Research`,
      teamList: `Corey Da Silva, Alexandra Thompson, Scott Tollerton`,
      timeline: `24 hours`,
      toolList: `Adobe Illustrator, Adobe Photoshop, Swift, Figma, iOS UI API and AR Kit`,

      //Overview Section
      overviewSection: true,
      tarUser2TF: false,
      tarUser3TF: false,
      researchM3TF: false,
      hmw3TF: false,
      projPrompttitle: `Embrace Saving Money`,
      projPrompt: `Financial institutions are constantly seeking ways to engage with young people, and to impart a basic understanding of financial concepts that are critical to their financial wellbeing. Saving money for future purchases is part of that education.`,
      designImg: `/assets/RealityCheque/Dprocess1B.svg`,
      designImgAlt: `Reality Cheque Design Process`,
      tarUsers1: `Millennials between the ages of 18 and 35`,
      tarUsers2: ``,
      tarUsers3: ``,
      researchMethods1: `Concept Research`,
      researchMethods2: `External Secondary Research`,
      researchMethods3: ``,

      personaAHeader: `Aspirations`,
      personaFHeader: `Frustrations`,
      personaIHeader: `Interests`,

      persona1Type: `Persona - Post Secondary Student`,
      personaImg1: `/assets/RealityCheque/EmilyJohnson2.png`,
      personaImgAlt1: `Post Secondary Student Persona`,
      persona1Name: `Emily Johnson`,
      persona1Title: `Communications Student`,
      persona1JobLocation: `Humber College`,
      persona1AspP1: `"I want to `,
      persona1AspHp1: `build a career `,
      persona1AspP2: `and `,
      persona1AspHp2: `have enough money `,
      persona1AspP3: `to make major purchases, like owning a car."`,
      persona1Frust1: `Feeling anxious about current financial burdens`,
      persona1Frust2: `Being unable to understand much of the financial advice presented`,
      persona1Frust3: ``,
      persona1Int1: `Enjoys spending time with friends and on enriching activities`,
      persona1Int2: `Seeking to better understand how her financial future will unfold`,

      persona2Type: `Persona - High School Graduate`,
      personaImg2: `/assets/RealityCheque/ChrisMitchell2.png`,
      personaImgAlt2: `High School Graduate Persona`,
      persona2Name: `Chris Mitchell`,
      persona2Title: `Service Industry Worker`,
      persona2JobLocation: `Vancouver, British Columbia`,
      persona2AspP1: `“Apply his entrepreneurial skills to `,
      persona2AspHp1: `start a small business `,
      persona2AspP2: `and achieve `,
      persona2AspHp2: `financial independence`,
      persona2AspP3: `.”`,
      persona2Frust1: `Not having the time to properly understand basic financial concepts critical to pursuing his goals`,
      persona2Frust2: ``,
      persona2Frust3: ``,
      persona2Int1: `Finding a better work-life balance`,
      persona2Int2: `Developing the skills needed to run a small business`,
      
      HMWQuestion1: `How can we empower millennials to understand the benefits associated with saving money?`,
      HMWQuestion2: `How can we improve the image of financial institutions so these people will go into their local branch?`,
      HMWQuestion3: ``,

      //Research Section
      researchSection: true,
      researchImg1TF: false,
      researchImg2TF: false,
      visualDataImg1TF: true,
      visualDataImg2TF: true,
      visualDataImg3TF: false,
      affinityDiagramTF: false,
      problemSection: true,

      QAnumberQ1TF: true,
      QAbulletsQ1TF: false,
      QAnumberQ2TF: true,
      QAbulletsQ2TF: false,
      QAnumberQ3TF: true,
      QAbulletsQ3TF: false,
      QAnumberQ4TF: false,
      QAbulletsQ4TF: false,
      QAnumberQ5TF: false,
      QAbulletsQ5TF: false,
      QAnumberQ6TF: false,
      QAbulletsQ6TF: false,

      QAbulletQ1a4TF: false,
      QAbulletQ2a4TF: false,
      QAbulletQ3a4TF: false,
      QAbulletQ4a4TF: false,
      QAbulletQ5a4TF: false,
      QAbulletQ6a4TF: false,

      researchGoal5TF: false,
      existingSys4TF: true,
      qaRow2TF: false,
      insightsTF: false,

      ST_Researh: `Research`,
      R_GoalTitle: `Investigation Goals`,
      researchGoal1: `Identify existing pain points for young millenials to learn about how they can save money.`,
      researchGoal2: `Analyze the current systems that exist to provide financial advice for personal savings.`,
      researchGoal3: `Examine how those systems can be made approachable for young millenials.`,
      researchGoal4: `Discover methods for saving money to be simply and easy to understand.`,
      researchGoal5: ``,
      EX_Research: `Existing Financial Planning Aids`,
      existingSys1: `Self-help books and education courses`,
      existingSys2: `Financial planning websites and blogs`,
      existingSys3: `Personal finance apps`,
      existingSys4: `Financial advisors`,
      researchImg1: ``,
      researchImg1Alt: ``,
      researchImg2: ``,
      researchImg2Alt: ``,
      SI_Research: `The Bigger Picture`,

      ResearchQ1: `How many millenials feel financially secure for their futures?`,
      NumberQ1: `50%`,
      AnswerQ1: `of millennials reported they are unable to save as much as they would like.`,
      bullet1Q1: ``,
      bullet2Q1: ``,
      bullet3Q1: ``,
      bullet4Q1: ``,

      ResearchQ2: `How many millenials are saving for retirement?`,
      NumberQ2: `26%`,
      AnswerQ2: `had started saving for retirement in 2020.`,
      bullet1Q2: ``,
      bullet2Q2: ``,
      bullet3Q2: ``,
      bullet4Q2: ``,

      ResearchQ3: `How easy is it for millenials to understand finances and managing money?`,
      NumberQ3: `43%`,
      AnswerQ3: `of Canadians aged 18 to 34 demonstrated a high level of financial literacy.`,
      bullet1Q3: ``,
      bullet2Q3: ``,
      bullet3Q3: ``,
      bullet4Q3: ``,
      
      visualDataImg1: `/assets/RealityCheque/Millennials_Savings.svg`,
      visualDataImg1Alt: `Millenial's Savings Data`,

      ResearchQ4: ``,
      NumberQ4: ``,
      AnswerQ4: ``,
      bullet1Q4: ``,
      bullet2Q4: ``,
      bullet3Q4: ``,
      bullet4Q4: ``,

      ResearchQ5: ``,
      NumberQ5: ``,
      AnswerQ5: ``,
      bullet1Q5: ``,
      bullet2Q5: ``,
      bullet3Q5: ``,
      bullet4Q5: ``,

      ResearchQ6: ``,
      NumberQ6: ``,
      AnswerQ6: ``,
      bullet1Q6: ``,
      bullet2Q6: ``,
      bullet3Q6: ``,
      bullet4Q6: ``,

      visualDataImg2: `/assets/RealityCheque/Saving_4_Downpayment.svg`,
      visualDataImg2Alt: `Saving for Downpayments Data`,
      visualDataImg3: ``,
      visualDataImg3Alt: ``,
      ST_AF_Diagram: ``,
      affinityImg: ``,
      affinityImgAlt: ``,
      R_Insights: ``,
      R_Pain: ``,
      insightImg1: ``,
      insightImg1Alt: ``,
      insightQuote1: ``,
      insightImg2: ``,
      insightImg2Alt: ``,
      insightQuote2: ``,
      insightImg3: ``,
      insightImg3Alt: ``,
      insightQuote3: ``,
      R_Findings: `Financial Confusion`,
      R_Finding1: `01`,
      findings1: `Many financial apps request information without explaining why the request is relevant. Some apps assume a pre-existing understanding of financial concepts and include too many variables and features, creating confusion.`,
      R_Finding2: `02`,
      findings2: `Financial planning websites often inundate users with related advertisements that push offerings at the user. This exacerbates personal data security concerns for many users.`,
      R_Finding3: `03`,
      findings3: `Financial advisors, while knowledgeable, are often difficult to casually engage.`,

      //Problem Statement
      problemHeader: `An Overwhelming Problem`,
      problemStatement: `While data and benefits related to saving money are accessible, they are often  obscured by large amounts of details and complexity, hindering effective communication and understanding.`,

      //Prototype Section
      prototypeSection: true,
      ST_Prototype: `Prototype`,
      wireframeTitle: `Creating a Tool`,
      wireframeImg: `/assets/RealityCheque/hifircWide+visuals.png`,
      wireframeImgAlt: `Reality Cheque Mockups and Graphics`,
      designDecisionQ: `What can we do to make saving money fun and appealing to young millenials?`,
      designDecisionA: `Provide Engaging, Simple to Understand Visuals`,
      designDecisionExplained: `Visually demonstrate how money grows over time by showing a growing pile of money.
      Use an augmented reality app to display the visuals in an engaging manner.
      Engage others in the discussion by allowing one user to share their pile of money with others.`,

      //Design Section
      DesignSection: true,
      visualIdentityImgTF: true,
      visualIdentityTF: true,
      feature1Img1TF: true,
      feature2Img1TF: true,
      feature2Img2TF: true,
      feature3Img1TF: true,
      designRow4TF: true,
      feature4Img1TF: true,
      feature4Img2TF: false,
      userJourneyTF: false,
      finalDesignTF: true,
      FDImgTF: false,
      FDVidTF: true,
      featurePicsTF: true,
      challengeSecTF: true,
      challengeBul3TF: true,
      challengeBul4TF: false,

      ST_Design: `Design`,
      VI_Title: `A Simple Appearance`,
      visualIdImg: `/assets/RealityCheque/Visual-Identity2.png`,
      visualIdImgAlt: `Reality Cheque Visual Identity`,
      DS_Title: `A Straightforward Solution`,
      feature1: `Augmented Reality App`,
      feature1Description: `Prototype dynamically generates piles of money based on user inputs, and renders a 3D image of the money against the user’s actual surroundings.`,
      featureNumber1: `01`,
      featureNumber2: `02`,
      featureNumber3: `03`,
      featureNumber4: `04`,
      feature1Img1: `/assets/RealityCheque/25yrsOfMoneys.png`,
      feature1Img1Alt: `Mobile Augmented Reality App`,
      feature1Caption: ``,
      feature2: `Simplified Step by Step`,
      feature2Description: `Minimize the information requested to speed up a user’s interaction with the app. This lets the user focus on the savings that accrue over time instead of providing an overly detailed financial forecast.`,
      feature2Img1: `/assets/RealityCheque/GettingStartedS.png`,
      feature2Img1Alt: `Reality Cheque App Start`,
      feature2Caption1: ``,
      feature2Img2: `/assets/RealityCheque/EmptyInputsS.png`,
      feature2Img2Alt: `Reality Cheque Forecast Inputs`,
      feature2Caption2: ``,
      feature3: `Visualizing the Growth`,
      feature3Description: `Different stages of savings would be shown, representing the growth in savings over time by expanding the size of the money pile.`,
      feature3Img1: `/assets/RealityCheque/10yrsOfMoneys.png`,
      feature3Img1Alt: `Reality Cheque Money Pile`,
      feature3Caption: ``,
      feature4: `Augmented Dollars`,
      feature4Description: `The bundles of money were developed to maximize their visual impact. Each bundle reflected one hundred dollars when rendered in the app.`,
      feature4Img1: `/assets/RealityCheque/3D_Cashs.png`,
      feature4Img1Alt: `Reality Cheque 3D Money`,
      feature4Caption1: ``,
      feature4Img2: ``,
      feature4Img2Alt: ``,
      feature4Caption2: ``,
      UJ_Title: ``,
      userJourneyImg: ``,
      userJourneyImgAlt: ``,
      FD_Title: `A Reality Cheque`,
      finalImg: ``,
      finalImgAlt: ``,
      finalVid:`/assets/RealityCheque/realitycheque.mp4`,
      finalVidAlt:`Reality Cheque Demo Video`,
      challengeTitle:`Making Saving Money Approachable`,
      challenge1:`How to engage millennials and generate a curiosity in personal finance, while instilling an awareness of the value of saving for future expenditures.`,
      solution1:`Developed a solution that engages these individuals through an interactive experience built into a mobile phone augmented reality app.`,
      challenge2:`How to present poorly understood financial concepts, like compound interest, in an easy to understand format.`,
      solution2:`Minimized the data input to generate an answer. Converted financial concepts into visual objects and used animation to reflect the growth that would occur over time.`,
      challenge3:`How to achieve social engagement around the concept of the beneficial value of saving.`,
      solution3:`Added the ability to share the user’s results with friends.`,
      challenge4:``,
      solution4:``,

      //Impact Section
      impactSection: true,
      impactImgTF: true,
      impactHeader: `A Dynamic Result`,
      impactStatement: `The augmented reality equipped mobile app created an easy to use and engaging visual presentation using 3D bundles of money that millennials can relate to.`,
      impactImg: `/assets/RealityCheque/art_endS.png`,
      impactImgAlt: `Bank Graphic`,

      //Feedback Section
      feedbackSection: false,
      feedbackrow1TF: false,
      feedbackImg1TF: false,
      feedbackrow2TF: false,
      feedbackImg2TF: false,
      feedbackrow3TF: false,
      feedbackImg3TF: false,
      ST_UserFeedback: ``,
      UT_Feedback_Title: ``,
      testFeedback1: ``,
      testFeedback2: ``,
      testFeedback3: ``,
      testFeedback4: ``,
      testFeedback5: ``,
      UT_Advantages_Title: ``,
      feedbackImg: ``,
      feedbackImgAlt: ``,
      feedbackAdv1: ``,
      feedbackAdv2: ``,
      feedbackAdv3: ``,
      feedbackAdv4: ``,
      feedbackAdv5: ``,
      feedbackAdvImg: ``,
      feedbackAdvImgAlt: ``,
      UT_Suggestions_Title: ``,
      feedbackSuggestion1: ``,
      feedbackSuggestion2: ``,
      feedbackSuggestion3: ``,
      feedbackSuggImg: ``,
      feedbackSuggImgAlt: ``,

      //Reflection Section
      reflectionSection: true,
      ST_Reflection: `Reflection`,
      takeawaysTitle: `Seeing the Value`,
      reflection1: `This working AR app on the Apple iOS platform demonstrated the potential impact augmented reality solutions can have on capturing the attention of a critical demographic to financial institutions, namely millennials. The mobile app was found to be easy to use and engaging, with the 3D bundles of money generating very appealing visuals.`,
      reflection2: `If developed further Reality Cheque could be made to integrate with social media, for sharing between individuals. With white-labelling options, this app could become part of a set of financial education aids used by financial institutions to interest these individuals in personal finance and financial products.`,
      reflection3: ``,

    },
    {
      id: `GameDev-UX`,
      //Splash Image at top of Page
      caseSplashImg: `/assets/GameDevUX/gamdevuxsplashS.png`,
      caseSplashImgAlt: `GameDev UX`,
      showSectionLine: {
        summaryLine: true,
        overviewLine: true,
        researchLine: true,
        designLine: true,
        problemLine: true,
        impactLine: true,
        prototypeLine: true,
        feedbackLine: true,
      },
      caseStyle: {
        background: 'linear-gradient(to top left, #8bcf66, #80cd68, #75cb6a, #6ac86c, #5ec66e)',
        backdropFilter: 'blur(4px)',
      },

      //Summary Section
      projTitle: `GameDev UX`,
      summary: `UX Game Guide is a knowledge transfer website that provides independent game developers with access to application specific user experience design methods and tutorials, enabling the developer to apply better UX design patterns within their game development process.`,
      myRole: `Project Management, Research, Prototyping, UX Design, UI Design`,
      teamList: `Scott Tollerton`,
      timeline: `3 Months`,
      toolList: `HTML, CSS, Figma, Virtual Studio Code, Adobe Creative Suite, Google Slides`,

      //Overview Section
      overviewSection: true,
      tarUser2TF: true,
      tarUser3TF: true,
      researchM3TF: true,
      hmw3TF: false,
      projPrompttitle: `Development Disadvantage`,
      projPrompt: `While the indie video game market continues to grow, user experience expectations for indie games are impacted by the quality of products released from well funded game development studios. This places the indie video game designer at a disadvantage, as access to specialized user experience knowledge sharing repositories, and to the experiences of other game designers, is lacking.`,
      designImg: `/assets/GameDevUX/designprocessGDux.svg`,
      designImgAlt: `GameDev UX Design Process`,
      tarUsers1: `Indie Game Developers`,
      tarUsers2: `Professional Game Developers`,
      tarUsers3: `Freelance or Solo Game Developers`,
      researchMethods1: `Concept Research`,
      researchMethods2: `External Secondary Research`,
      researchMethods3: `Surveys and Interviews`,

      personaAHeader: `Aspirations`,
      personaFHeader: `Frustrations`,
      personaIHeader: `Interests`,

      persona1Type: `Persona - Game Design Graduate`,
      personaImg1: `/assets/GameDevUX/JeffBartonS.png`,
      personaImgAlt1: `Graduate Persona`,
      persona1Name: `Jeff Barton`,
      persona1Title: `Game Design Student`,
      persona1JobLocation: `Sheridan College`,
      persona1AspP1: `"I want to `,
      persona1AspHp1: `build my skills `,
      persona1AspP2: `so I can have a career as a video game designer and developer."`,
      persona1AspHp2: ``,
      persona1AspP3: ``,
      persona1Frust1: `Formal education lags the current state of game design and development techniques in the video game industry.`,
      persona1Frust2: `Needs to find alternate sources for current design thinking and development aids.`,
      persona1Frust3: ``,
      persona1Int1: `Staying current as video game trends rapidly evolve.`,
      persona1Int2: `Translating his user experiences as a gamer into design insights as he plans out his first video game.`,

      persona2Type: `Persona - Video Game Developer`,
      personaImg2: `/assets/GameDevUX/ToddRichardsS.png`,
      personaImgAlt2: `Game Developer Persona`,
      persona2Name: `Todd Richards`,
      persona2Title: `Game Developer`,
      persona2JobLocation: `Toronto, Ontario`,
      persona2AspP1: `“I want to `,
      persona2AspHp1: `control my own creative process`,
      persona2AspP2: `, without oversight and constraints imposed by the studio and management.”`,
      persona2AspHp2: ``,
      persona2AspP3: ``,
      persona2Frust1: `Having senior leadership dilute or alter his UX designs without understanding developing trends in the industry.`,
      persona2Frust2: `Lack of video game development assistance outside of the resources provided by his current employer.`,
      persona2Frust3: ``,
      persona2Int1: `Experimenting with cutting-edge digital technologies to create unique new gaming experiences.`,
      persona2Int2: ``,

      HMWQuestion1: `How might we integrate user experience design into the game development process to enhance the quality of indie games?`,
      HMWQuestion2: `How might we ensure user experience techniques can be learned and shared within the indie game developer community?`,
      HMWQuestion3: ``,

      //Research Section
      researchSection: true,
      researchImg1TF: true,
      researchImg2TF: true,
      visualDataImg1TF: true,
      visualDataImg2TF: true,
      visualDataImg3TF: true,
      affinityDiagramTF: true,
      problemSection: true,

      QAnumberQ1TF: true,
      QAbulletsQ1TF: false,
      QAnumberQ2TF: true,
      QAbulletsQ2TF: false,
      QAnumberQ3TF: true,
      QAbulletsQ3TF: false,
      QAnumberQ4TF: false,
      QAbulletsQ4TF: true,
      QAnumberQ5TF: true,
      QAbulletsQ5TF: false,
      QAnumberQ6TF: true,
      QAbulletsQ6TF: false,

      QAbulletQ1a4TF: false,
      QAbulletQ2a4TF: false,
      QAbulletQ3a4TF: false,
      QAbulletQ4a4TF: true,
      QAbulletQ5a4TF: false,
      QAbulletQ6a4TF: false,

      researchGoal5TF: true,
      existingSys4TF: true,
      qaRow2TF: true,
      insightsTF: true,

      ST_Researh: `Research`,
      R_GoalTitle: `Assessing the Situation`,
      researchGoal1: `Discover where game developers find resources outside of education.`,
      researchGoal2: `Examine the development of indie games.`,
      researchGoal3: `Identify why indie games are created.`,
      researchGoal4: `Discover what tools are used during development.`,
      researchGoal5: `Analyze the indie game industry market growth.`,
      EX_Research: `Existing Tools`,
      existingSys1: `Reference Documentation`,
      existingSys2: `Education Courses and Textbooks`,
      existingSys3: `Forums and Websites`,
      existingSys4: `Online Tutorials`,
      researchImg1: `/assets/GameDevUX/Player-Preference-Data-sm.png`,
      researchImg1Alt: `Player's Game Preference Data`,
      researchImg2: `/assets/GameDevUX/Indie-Game-Players-Data-sm.png`,
      researchImg2Alt: `Indie Game Players Data`,
      
      SI_Research: `Datamining the Issues`,

      ResearchQ1: `How do developers source player feedback?`,
      NumberQ1: `20%`,
      AnswerQ1: `use hired testers`,
      bullet1Q1: ``,
      bullet2Q1: ``,
      bullet3Q1: ``,
      bullet4Q1: ``,

      ResearchQ2: `Are mobile apps useful with development?`,
      NumberQ2: `40%`,
      AnswerQ2: `of devs found them useful`,
      bullet1Q2: ``,
      bullet2Q2: ``,
      bullet3Q2: ``,
      bullet4Q2: ``,

      ResearchQ3: `Would developers use a mobile device during development?`,
      NumberQ3: `No`,
      AnswerQ3: `Unless developing a game for mobile`,
      bullet1Q3: ``,
      bullet2Q3: ``,
      bullet3Q3: ``,
      bullet4Q3: ``,

      visualDataImg1: `/assets/GameDevUX/Developer-Resource-Tools-Usage.svg`,
      visualDataImg1Alt: `Developer Tools Usage Data`,

      ResearchQ4: `Where do developers find learning tools for their skills?`,
      NumberQ4: ``,
      AnswerQ4: `Online sources`,
      bullet1Q4: `YouTube`,
      bullet2Q4: `Udemy`,
      bullet3Q4: `Forums`,
      bullet4Q4: `Tutorial Websites`,

      ResearchQ5: `How fast is the Indie Game market growing?`,
      NumberQ5: `345%`,
      AnswerQ5: `more indie games launched from 2014 to 2017`,
      bullet1Q5: ``,
      bullet2Q5: ``,
      bullet3Q5: ``,
      bullet4Q5: ``,

      ResearchQ6: `How long does it take to make a game?`,
      NumberQ6: `3-7`,
      AnswerQ6: `years`,
      bullet1Q6: ``,
      bullet2Q6: ``,
      bullet3Q6: ``,
      bullet4Q6: ``,

      visualDataImg2: `/assets/GameDevUX/Development-Time-Data.png`,
      visualDataImg2Alt: `Indie Game Development Time Data`,
      visualDataImg3: `/assets/GameDevUX/IndieGamesonSteam.svg`,
      visualDataImg3Alt: `Indie Games Released on Steam Data`,
      ST_AF_Diagram: `Insight into the Developer`,
      affinityImg: `/assets/GameDevUX/Empathy_Map.svg`,
      affinityImgAlt: `Game Developer Empathy Map`,
      R_Insights: `Development Difficulties`,
      R_Pain: `Pain Points`,
      insightImg1: `/assets/GameDevUX/head1.png`,
      insightImg1Alt: `Developer in Black Shirt`,
      insightQuote1: `“It’s difficult to get feedback on your game when its in closed door production. There are too many pieces to tie together and requires multiple people to make a complete game. Game development to me means game programming.”`,
      insightImg2: `/assets/GameDevUX/head2.png`,
      insightImg2Alt: `Developer in Purple Shirt`,
      insightQuote2: `"Devs that I know tend to learn new skills inside and outside of work. There isn't one specific tool that we can go to, so the internet as a whole tends to be our best friend when it comes to learning new skill sets."`,
      insightImg3: `/assets/GameDevUX/head3.png`,
      insightImg3Alt: `Developer in Blue Shirt`,
      insightQuote3: `"I know a lot of indie developers that are friends of ours who put out a game that did great, I can’t break down why players come back or don’t come back; they’re on Fortnite
      or something else.”`,
      R_Findings: `Breaking it Down`,
      R_Finding1: `01`,
      findings1: `Indie game developers do not have the same access to the knowledge and benefits that user focused design provides.`,
      R_Finding2: `02`,
      findings2: `To polish or improve their skills or resolve technical problems, indie game developers rely on online resources.`,
      R_Finding3: `03`,
      findings3: `Indie game developers usually work in small specialized teams with low budgets and limited resources.`,

      //Problem Statement
      problemHeader: `Understanding the Problem`,
      problemStatement: `User experience design and methods are not necessarily known or easily accessible to learn for most indie game developers. This makes it difficult for them to ensure their years of game development produce something that is well received by players.`,

      //Prototype Section
      prototypeSection: true,
      ST_Prototype: `Prototype`,
      wireframeTitle: `Creating a Resource`,
      wireframeImg: `/assets/GameDevUX/wireframes.svg`,
      wireframeImgAlt: `GameDev UX Mockups`,
      designDecisionQ: `What would be the most effective way to make UX methods easily available and accessible to game developers?`,
      designDecisionA: `Creating a website tool would appeal the the most developers and be easily discoverable and sharable as a resource between developers.`,
      designDecisionExplained: `Almost all avenues for indie game developers to gain knowledge of technical or user experience design methods had a barrier that would limit their practical use. Educational programs to teach new skills would cost both time and money or educational books which would require pre-requisite understanding and time. Most game developers seek answers online in communities, forums, and tutorials scattered across sites like Youtube. Creating a web based knowledge base with simple UX methods that could be translated easily into use in the game developement process was determined to be the most effective solution.`,

      //Design Section
      DesignSection: true,
      visualIdentityImgTF: true,
      visualIdentityTF: true,
      feature1Img1TF: true,
      feature2Img1TF: true,
      feature2Img2TF: true,
      feature3Img1TF: true,
      designRow4TF: true,
      feature4Img1TF: true,
      feature4Img2TF: true,
      userJourneyTF: false,
      finalDesignTF: true,
      FDImgTF: true,
      FDVidTF: false,
      featurePicsTF: true,
      challengeSecTF: true,
      challengeBul3TF: false,
      challengeBul4TF: false,

      ST_Design: `Design Concept`,
      VI_Title: `Minimalist Aesthetic`,
      visualIdImg: `/assets/GameDevUX/Visual-Identity.PNG`,
      visualIdImgAlt: `GameDev UX Visual Identity`,
      DS_Title: `Developing Positive Experiences`,
      featureNumber1: `01`,
      featureNumber2: `02`,
      featureNumber3: `03`,
      featureNumber4: `04`,
      feature1: `UX Methods Checklist`,
      feature1Description: `The website acts as a comprehensive UX design resource based around a checklist to assist developers with understanding the role of UX methods and patterns within the game development process.`,
      feature1Img1: `/assets/GameDevUX/Methods_Checklist_DesktopS.png`,
      feature1Img1Alt: `GameDev UX Methods Checklist`,
      feature1Caption: ``,
      feature2: `Designed for Game Development`,
      feature2Description: `The GameDev UX Checklist is designed to cover the stages of game development, providing step-by-step guidance tailored to each phase. The layout of the website ensures developers understand and implement various UX methods at the appropriate stage, when developing their project's gaming experience.`,
      feature2Img1: `/assets/GameDevUX/GameDev_Process_V-DesktopS.png`,
      feature2Img1Alt: `GameDev UX for Game Development Process`,
      feature2Caption1: ``,
      feature2Img2: `/assets/GameDevUX/GameDev_Process2_V-DesktopS.png`,
      feature2Img2Alt: ``,
      feature2Caption2: ``,
      feature3: `FAQ Section`,
      feature3Description: `A FAQ section answers prompts and questions that arise from the checklists that may not easily fit within the game development process, but can still arise.`,
      feature3Img1: `/assets/GameDevUX/FAQ_DesktopS.png`,
      feature3Img1Alt: `GameDev UX FAQ`,
      feature3Caption: ``,
      feature4: `Simplified Walkthroughs`,
      feature4Description: `Each method includes a simplified walk through, along with the benefits associated with the use of the method. Visuals that guide the application of the method are provided where appropriate.`,
      feature4Img1: `/assets/GameDevUX/Method_walkthrough_V-DesktopS.png`,
      feature4Img1Alt: `GameDev UX Competitive Audit`,
      feature4Caption1: ``,
      feature4Img2: `/assets/GameDevUX/Method_walkthrough2_V-DesktopS.png`,
      feature4Img2Alt: `GameDev UX Usability Testing`,
      feature4Caption2: ``,
      UJ_Title: ``,
      userJourneyImg: ``,
      userJourneyImgAlt: ``,
      FD_Title: `UX Game Development Aid`,
      finalImg: `/assets/GameDevUX/FinalMonitors.png`,
      finalImgAlt: `GameDev UX on Monitors`,
      finalVid:``,
      finalVidAlt:``,
      challengeTitle:`Targeting the Solution`,
      challenge1:`Provide a source of guidance to independent developers lacking access to the in-house resources of larger video game companies.`,
      solution1:`Reduces the effort and likelihood of developing a player experience that won’t resonate with the gamer community.`,
      challenge2:`Provide easy to understand walk-throughs of UX methods specifically targeting the indie game developer.`,
      solution2:`Improves the quality of the indie game developer’s player experience.`,
      challenge3:``,
      solution3:``,
      challenge4:``,
      solution4:``,

      //Impact Section
      impactSection: true,
      impactImgTF: true,
      impactHeader: `Developing Positive Experiences`,
      impactStatement: `The existence of an indie game developer knowledge base of design methods will reduce the effort needed to apply sound UX design methods to game development and improve the acceptance of the end product by the gaming community.`,
      impactImg: ``,
      impactImgAlt: ``,

      //Feedback Section
      feedbackSection: true,
      feedbackrow1TF: true,
      feedbackImg1TF: true,
      feedbackrow2TF: false,
      feedbackImg2TF: false,
      feedbackrow3TF: false,
      feedbackImg3TF: false,
      ST_UserFeedback: `User Feedback`,
      UT_Feedback_Title: `Put to the Test`,
      testFeedback1: `Using the GameDev UX checklist felt good, very clear and minimal, rewarding as I ticked off the steps I completed.`,
      testFeedback2: `The navigation of the checklist was simple and easy to use.`,
      testFeedback3: `The visualizations helped make the methods clear and easier to understand.`,
      testFeedback4: `I can think of times that I would have benefitted from these methods in the past during game development`,
      testFeedback5: ``,
      UT_Advantages_Title: ``,
      feedbackImg: `/assets/GameDevUX/testing1S.PNG`,
      feedbackImgAlt: `GameDev UX User Testing`,
      feedbackAdv1: ``,
      feedbackAdv2: ``,
      feedbackAdv3: ``,
      feedbackAdv4: ``,
      feedbackAdv5: ``,
      feedbackAdvImg: ``,
      feedbackAdvImgAlt: ``,
      UT_Suggestions_Title: ``,
      feedbackSuggestion1: ``,
      feedbackSuggestion2: ``,
      feedbackSuggestion3: ``,
      feedbackSuggImg: ``,
      feedbackSuggImgAlt: ``,

      //Reflection Section
      reflectionSection: true,
      ST_Reflection: `Reflection`,
      takeawaysTitle: `Leveling Up`,
      reflection1: `Surveying game developers in different working environments highlighted a significant disadvantage in the area of UX design that indie game developers cope with, when compared to their colleagues working at the larger game development studios.`,
      reflection2: `Proposing the combination of design methods with user testing and research provided feedback that validated the proposed solution. How guidance is structured influences how well received it will be received.`,
      reflection3: `The vehicle for communicating the methods was especially important for this project, and was subject to multiple iterations. Speaking directly to the users and user testing was crucial for these insights.`,

    },
    
    {
      id: `Forj`,
      //Splash Image at top of Page
      caseSplashImg: `/assets/Forj/FORJ_horizontal_lg_rgbHEX_white.png`,
      caseSplashImgAlt: `Forj Logo`,
      showSectionLine: {
        summaryLine: true,
        overviewLine: false,
        researchLine: false,
        designLine: true,
        problemLine: false,
        impactLine: false,
        prototypeLine: false,
        feedbackLine: false,
      },
      caseStyle: {
        backgroundColor: '#362e40',
      },

      //Summary Section
      projTitle: `A Virtual Event Management Platform - Forj`,
      summary: `Forj’s event management platform creates client unique, virtual conferencing spaces. Working with clients, customized the user experience (UX) of Forj’s virtual conference spaces platform to meet each client’s needs. Collaborated with the backend development team to develop a new user interface (UI) for their next web-based customer event hosting platform. `,
      myRole: `Visual Website Design, UX design, UI design, Accessibility Compliance, and Implementing Client Branding`,
      teamList: `Scott Tollerton`,
      timeline: `November, 2020 - February, 2023`,
      toolList: `Figma, HTML, CSS, JavaScript, NVDA, Adobe Photoshop, Adobe Illustrator, Asana, Notion`,

      //Overview Section
      overviewSection: false,
      tarUser2TF: false,
      tarUser3TF: false,
      researchM3TF: false,
      hmw3TF: false,
      projPrompttitle: ``,
      projPrompt: ``,
      designImg: ``,
      designImgAlt: ``,
      tarUsers1: ``,
      tarUsers2: ``,
      tarUsers3: ``,
      researchMethods1: ``,
      researchMethods2: ``,
      researchMethods3: ``,

      personaAHeader: ``,
      personaFHeader: ``,
      personaIHeader: ``,

      persona1Type: ``,
      personaImg1: ``,
      personaImgAlt1: ``,
      persona1Name: ``,
      persona1Title: ``,
      persona1JobLocation: ``,
      persona1Aspiration: ``,
      persona1Frustration: ``,
      persona1Interest: ``,

      persona2Type: ``,
      personaImg2: ``,
      personaImgAlt2: ``,
      persona2Name: ``,
      persona2Title: ``,
      persona2AspP1: ``,
      persona2AspHp1: ``,
      persona2AspP2: ``,
      persona2AspHp2: ``,
      persona2AspP3: ``,
      persona2Frust1: ``,
      persona2Frust2: ``,
      persona2Frust3: ``,
      persona2Int1: ``,
      persona2Int2: ``,

      HMWQuestion1: ``,
      HMWQuestion2: ``,
      HMWQuestion3: ``,

      //Research Section
      researchSection: false,
      researchImg1TF: false,
      researchImg2TF: false,
      visualDataImg1TF: false,
      visualDataImg2TF: false,
      visualDataImg3TF: false,
      affinityDiagramTF: false,
      problemSection: false,

      QAnumberQ1TF: false,
      QAbulletsQ1TF: false,
      QAnumberQ2TF: false,
      QAbulletsQ2TF: false,
      QAnumberQ3TF: false,
      QAbulletsQ3TF: false,
      QAnumberQ4TF: false,
      QAbulletsQ4TF: false,
      QAnumberQ5TF: false,
      QAbulletsQ5TF: false,
      QAnumberQ6TF: false,
      QAbulletsQ6TF: false,

      QAbulletQ1a4TF: false,
      QAbulletQ2a4TF: false,
      QAbulletQ3a4TF: false,
      QAbulletQ4a4TF: false,
      QAbulletQ5a4TF: false,
      QAbulletQ6a4TF: false,

      researchGoal5TF: false,
      existingSys4TF: false,
      qaRow2TF: false,
      insightsTF: false,

      ST_Researh: ``,
      R_GoalTitle: ``,
      researchGoal1: ``,
      researchGoal2: ``,
      researchGoal3: ``,
      researchGoal4: ``,
      researchGoal5: ``,
      EX_Research: ``,
      existingSys1: ``,
      existingSys2: ``,
      existingSys3: ``,
      existingSys4: ``,
      researchImg1: ``,
      researchImg1Alt: ``,
      researchImg2: ``,
      researchImg2Alt: ``,
      SI_Research: ``,
      visualDataImg1: ``,
      visualDataImg1Alt: ``,

      ResearchQ1: ``,
      NumberQ1: ``,
      AnswerQ1: ``,
      bullet1Q1: ``,
      bullet2Q1: ``,
      bullet3Q1: ``,
      bullet4Q1: ``,

      ResearchQ2: ``,
      NumberQ2: ``,
      AnswerQ2: ``,
      bullet1Q2: ``,
      bullet2Q2: ``,
      bullet3Q2: ``,
      bullet4Q2: ``,

      ResearchQ3: ``,
      NumberQ3: ``,
      AnswerQ3: ``,
      bullet1Q3: ``,
      bullet2Q3: ``,
      bullet3Q3: ``,
      bullet4Q3: ``,

      ResearchQ4: ``,
      NumberQ4: ``,
      AnswerQ4: ``,
      bullet1Q4: ``,
      bullet2Q4: ``,
      bullet3Q4: ``,
      bullet4Q4: ``,

      ResearchQ5: ``,
      NumberQ5: ``,
      AnswerQ5: ``,
      bullet1Q5: ``,
      bullet2Q5: ``,
      bullet3Q5: ``,
      bullet4Q5: ``,

      ResearchQ6: ``,
      NumberQ6: ``,
      AnswerQ6: ``,
      bullet1Q6: ``,
      bullet2Q6: ``,
      bullet3Q6: ``,
      bullet4Q6: ``,

      visualDataImg2: ``,
      visualDataImg2Alt: ``,
      visualDataImg3: ``,
      visualDataImg3Alt: ``,
      ST_AF_Diagram: ``,
      affinityImg: ``,
      affinityImgAlt: ``,
      R_Insights: ``,
      R_Pain: ``,
      insightImg1: ``,
      insightImg1Alt: ``,
      insightQuote1: ``,
      insightImg2: ``,
      insightImg2Alt: ``,
      insightQuote2: ``,
      insightImg3: ``,
      insightImg3Alt: ``,
      insightQuote3: ``,
      R_Findings: ``,
      R_Finding1: ``,
      findings1: ``,
      R_Finding2: ``,
      findings2: ``,
      R_Finding3: ``,
      findings3: ``,

      //Problem Statement
      problemHeader: ``,
      problemStatement: ``,

      //Prototype Section
      prototypeSection: false,
      ST_Prototype: ``,
      wireframeTitle: ``,
      wireframeImg: ``,
      wireframeImgAlt: ``,
      designDecisionQ: ``,
      designDecisionA: ``,
      designDecisionExplained: ``,

      //Design Section
      DesignSection: true,
      visualIdentityImgTF: false,
      visualIdentityTF: false,
      feature1Img1TF: false,
      feature2Img1TF: false,
      feature2Img2TF: false,
      feature3Img1TF: false,
      designRow4TF: false,
      feature4Img1TF: false,
      feature4Img2TF: false,
      userJourneyTF: false,
      finalDesignTF: false,
      FDImgTF: true,
      FDVidTF: false,
      featurePicsTF: false,
      challengeSecTF: false,
      challengeBul3TF: false,
      challengeBul4TF: false,

      ST_Design: ``,
      VI_Title: ``,
      visualIdImg: ``,
      visualIdImgAlt: ``,
      DS_Title: `Efforts and Impact`,
      featureNumber1: `01`,
      featureNumber2: `02`,
      featureNumber3: `03`,
      featureNumber4: `04`,
      feature1: `Tailored Event Experiences`,
      feature1Description: `Designed and launched interactive web event virtual spaces based on clients needs. Ensured user interfaces, visual design, curated assets, branding and accessibility met client requirements.`,
      feature1Img1: ``,
      feature1Img1Alt: ``,
      feature1Caption: ``,
      feature2: `Provided UX Insights`,
      feature2Description: `Guided product development team as the user experience designer and advised the team how to address the observed needs of the clients and their users.`,
      feature2Img1: ``,
      feature2Img1Alt: ``,
      feature2Caption1: ``,
      feature2Img2: ``,
      feature2Img2Alt: ``,
      feature2Caption2: ``,
      feature3: `Kept Users in Mind`,
      feature3Description: `Made user centered design the focal point of all platform feature enhancement discussions. Enhancements had to address prioritized user needs or specific client requests.`,
      feature3Img1: ``,
      feature3Img1Alt: ``,
      feature3Caption: ``,
      feature4: `Established Accessibility`,
      feature4Description: `Ensured WCAG 2.0 and AA level accessibility guidelines were followed across both current and under development systems. Carried out accessibility testing to verify non-compliant items that were identified and ultimately resolved.`,
      feature4Img1: ``,
      feature4Img1Alt: ``,
      feature4Caption1: ``,
      feature4Img2: ``,
      feature4Img2Alt: ``,
      feature4Caption2: ``,
      UJ_Title: ``,
      userJourneyImg: ``,
      userJourneyImgAlt: ``,
      FD_Title: ``,
      finalImg: ``,
      finalImgAlt: ``,
      finalVid:``,
      finalVidAlt:``,
      challengeTitle:``,
      challenge1:``,
      solution1:``,
      challenge2:``,
      solution2:``,
      challenge3:``,
      solution3:``,
      challenge4:``,
      solution4:``,

      //Impact Section
      impactSection: false,
      impactImgTF: true,
      impactHeader: ``,
      impactStatement: ``,
      impactImg: ``,
      impactImgAlt: ``,

      //Feedback Section
      feedbackSection: false,
      feedbackrow1TF: false,
      feedbackImg1TF: false,
      feedbackrow2TF: false,
      feedbackImg2TF: false,
      feedbackrow3TF: false,
      feedbackImg3TF: false,
      ST_UserFeedback: ``,
      UT_Feedback_Title: ``,
      testFeedback1: ``,
      testFeedback2: ``,
      testFeedback3: ``,
      testFeedback4: ``,
      testFeedback5: ``,
      UT_Advantages_Title: ``,
      feedbackImg: ``,
      feedbackImgAlt: ``,
      feedbackAdv1: ``,
      feedbackAdv2: ``,
      feedbackAdv3: ``,
      feedbackAdv4: ``,
      feedbackAdv5: ``,
      feedbackAdvImg: ``,
      feedbackAdvImgAlt: ``,
      UT_Suggestions_Title: ``,
      feedbackSuggestion1: ``,
      feedbackSuggestion2: ``,
      feedbackSuggestion3: ``,
      feedbackSuggImg: ``,
      feedbackSuggImgAlt: ``,

      //Reflection Section
      reflectionSection: true,
      ST_Reflection: ``,
      takeawaysTitle: `My Forj Experience`,
      reflection1: `While working at Forj, I contributed to several different areas of the company. Each helped to broaden my understanding of the efforts that must cooperatively come together to create web-based applications that successfully engage the user. Collaborating with the development team to build a new platform and new user interface was an invaluable experience. Being able to delve into user experience (UX) and user interface (UI) design principles more deeply has expanded my set of skills and understanding of the user experience.​`,
      reflection2: ``,
      reflection3: `Project specifics are under a NDA. Please contact me with any questions regarding my work on these projects.`,

    },
];
  
  export default caseData;
  